import React from "react";
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {ExternalLink} from "../../../../components/common/ExternalLink";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import PropTypes from "prop-types";

export const BpieDistrictPieIntroView = ({updateDistrictBpieStatus}) => {
    return <>
        <p>
            <b>
                Purpose: District personnel, FIN Facilitators, and other IDEA funded state projects, as appropriate,
                will use the Plan for Inclusive Education to collaboratively develop short and long-term improvement
                efforts to address prioritized indicators from the Best Practices for Inclusive Education (BPIE)
                assessment.
            </b>
        </p>
        <p>
            <ExternalLink label={`The Bureau of Exceptional Education and Student Services (BEESS) Strategic Plan`}
                          url={`https://www.fldoe.org/academics/exceptional-student-edu/data/`}/>
            {` includes goals to increase
            regular class placement of students with disabilities, decrease separate class placement of students with
            disabilities and decrease other separate environment placement of students with disabilities resulting in
            increased outcomes students with disabilities across all districts. This will be accomplished by developing,
            implementing, and monitoring the Plan for Inclusive Education.`}
        </p>

        <Allow policy={BpiePolicy} policyEvent={policyEvents.districtPieAssessments.MODIFY}>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Begin District PIEs"}
                    name="btnSave"
                    onClick={updateDistrictBpieStatus}
                />
            </ButtonBar>
        </Allow>
    </>;
};

BpieDistrictPieIntroView.propTypes = {
    updateDistrictBpieStatus: PropTypes.func.isRequired
};

