import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {ExternalLink} from "../../../../components/common/ExternalLink";
import FormTable from "../../../../components/common/FormTable";
import GridTable from "../../../../components/common/GridTable";
import {TextField} from "../../../../components/common/inputs";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import {
    clearInputFormErrorFields,
    inputFormIsValid, useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../components/common/inputs/SelectField";
import TextArea from "../../../../components/common/inputs/TextArea";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {DistrictPieIndicatorTitle, DistrictPieIndicatorTypeList} from "../../bpieConstants";
import {createDpieDistrictInfoDetails, createSaveDpieDistrictInfoDetailsObject} from "../bpieDistrictObjectFactory";
import {BpieDistrictPieDetailsPriorityIndicators} from "./bpieDistrictPieDetailsPriorityIndicators";

export const BpieDistrictPieDetailsForm = ({
                                               dbpiePriorityIndicators,
                                               districtContacts,
                                               districtPie,
                                               handleDataChanged,
                                               handleDistrictPieSummaryRedirect,
                                               handleSaveDistrictPieDetails,
                                               hasTopPriorityIndicators,
                                               isEditable
                                           }) => {
    const dpieDetailsForm = useFormInputObject(createDpieDistrictInfoDetails(districtPie), () => handleDataChanged());

    const handleSaveDpieDetailsClick = (event) => {
        event.preventDefault();
        saveDistrictDetails();
    };

    const saveDistrictDetails = () => {
        const isValid = validateForm();
        if (isValid) {
            const result = createSaveDpieDistrictInfoDetailsObject(dpieDetailsForm, districtPie);
            handleSaveDistrictPieDetails(result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(dpieDetailsForm);

        if (isTrimmedStringEmpty(dpieDetailsForm.districtContactId.value))
            dpieDetailsForm.districtContactId.setError("Required");

        if (isTrimmedStringEmpty(dpieDetailsForm.teamMembers.value))
            dpieDetailsForm.teamMembers.setError("Required");

        if (isTrimmedStringEmpty(dpieDetailsForm.dateMeetingHeld.value))
            dpieDetailsForm.dateMeetingHeld.setError("Required");

        for(let indicatorType of DistrictPieIndicatorTypeList) {
            if (isTrimmedStringEmpty(dpieDetailsForm[`stateTarget_${indicatorType.id}`].value))
                dpieDetailsForm[`stateTarget_${indicatorType.id}`].setError("Required");

            if (isTrimmedStringEmpty(dpieDetailsForm[`currentDistrictDataAndSource_${indicatorType.id}`].value))
                dpieDetailsForm[`currentDistrictDataAndSource_${indicatorType.id}`].setError("Required");

            if (isTrimmedStringEmpty(dpieDetailsForm[`districtTarget_${indicatorType.id}`].value))
                dpieDetailsForm[`districtTarget_${indicatorType.id}`].setError("Required");
        }

        let isValid = inputFormIsValid(dpieDetailsForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    if (!hasTopPriorityIndicators) return null;

    return <>
        <FormTable>
            <SelectField
                {...dpieDetailsForm.districtContactId}
                defaultOption={`Select District Contact`}
                disabled={!isEditable}
                includeDefaultOption
                label={`District Contact`}
                medium={6}
                options={districtContacts}
                showLabel
            />
            <TextArea
                {...dpieDetailsForm.teamMembers}
                disabled={!isEditable}
                label={'Add all team member names and titles/positions.'}
                showLabel
            />
            <DateTimePickerField
                {...dpieDetailsForm.dateMeetingHeld}
                disabled={!isEditable}
                inputClass={`input--small`}
                label="Date Meeting Held"
                showLabel
            />
        </FormTable>

        <GridTable>
            <thead>
            <tr>
                <th colSpan={4} className={`text-center`}>{DistrictPieIndicatorTitle}</th>
            </tr>
            <tr>
                <th className={`text-center`}>Indicator</th>
                <th className={`text-center`}>State Target</th>
                <th className={`text-center`}>Current District Data and Source</th>
                <th className={`text-center`}>District Target</th>
            </tr>
            </thead>
            <tbody>
            {
                DistrictPieIndicatorTypeList.map((indicatorType, index) => {
                    return (
                        <tr key={`indicatorType_${index}`}>
                            <td>{indicatorType.text}</td>
                            <td>
                                <TextField
                                    {...dpieDetailsForm[`stateTarget_${indicatorType.id}`]}
                                    disabled={!isEditable}
                                    label={`State Target for ${indicatorType.text}`}
                                    name={`stateTarget_${indicatorType.id}`}
                                    showLabel={false}
                                />
                            </td>
                            <td>
                                <TextField
                                    {...dpieDetailsForm[`currentDistrictDataAndSource_${indicatorType.id}`]}
                                    disabled={!isEditable}
                                    label={`Current District Data and Source for ${indicatorType.text}`}
                                    name={`currentDistrictDataAndSource_${indicatorType.id}`}
                                    showLabel={false}
                                />
                            </td>
                            <td>
                                <TextField
                                    {...dpieDetailsForm[`districtTarget_${indicatorType.id}`]}
                                    disabled={!isEditable}
                                    label={`District Target for ${indicatorType.text}`}
                                    name={`districtTarget_${indicatorType.id}`}
                                    showLabel={false}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            <tr>
                <td colSpan={4}>
                    <ExternalLink label={`State and Local Agency Annual Performance Report`} url={`https://www.fldoe.org/academics/exceptional-student-edu/data/`} />
                </td>
            </tr>
            </tbody>
        </GridTable>

        <BpieDistrictPieDetailsPriorityIndicators
            dbpiePriorityIndicators={dbpiePriorityIndicators}
            sbpiePriorityIndicators={districtPie.topSbpieIndicators}
        />

        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
            {
                isEditable &&
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Save Team and Indicator Details"}
                    name="btnSave"
                    onClick={handleSaveDpieDetailsClick}
                />
            }
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Summary`}
                name={`btnReturnDpieSummary`}
                onClick={handleDistrictPieSummaryRedirect}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieDetailsForm.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtContacts: PropTypes.array,
    districtPie: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    handleSaveDistrictPieDetails: PropTypes.func.isRequired,
    hasTopPriorityIndicators: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
};