import React from 'react';
import PropTypes from 'prop-types';
import {
    dateSelectOptionChoices,
    previousDateSelectFieldOptionsList,
    futureDateSelectFieldOptionsList,
    upcomingDateSelectFieldOptionsList,
    quartersDateSelectFieldOptionsList,
    quartersWithCustomDateSelectFieldOptionsList
} from "../../../constants/inputConstants";
import GridColumn from "../GridColumn";
import DateTimePickerField from "./DateTimePickerField";
import {getDateRangeFromDescription} from "./inputUtility";
import SelectField from "./SelectField";

const DateSelectField = ({
                             customOnly = false,
                             dateRangeType = "",
                             defaultOption = "Select a date range",
                             disabled = false,
                             endDate,
                             includeDefaultOption = true,
                             label,
                             labelClass = "",
                             medium,
                             name,
                             onSelectDate,
                             optionsToUse = dateSelectOptionChoices.previous,
                             showLabel,
                             startDate,
                             wrapperClass
                         }) => {
    const Custom = "Custom";

    if (!showLabel) {
        labelClass += " is-visuallyhidden";
    }

    let options = [];
    if (optionsToUse === dateSelectOptionChoices.previous) {
        options = [...previousDateSelectFieldOptionsList];
    } else if (optionsToUse === dateSelectOptionChoices.future) {
        options = [...futureDateSelectFieldOptionsList];
    } else if (optionsToUse === dateSelectOptionChoices.upcoming) {
        options = [...upcomingDateSelectFieldOptionsList];
    } else if (optionsToUse === dateSelectOptionChoices.quarters) {
        options = [...quartersDateSelectFieldOptionsList];
    } else if (optionsToUse === dateSelectOptionChoices.quartersWithCustom) {
        options = [...quartersWithCustomDateSelectFieldOptionsList];
    }

    const onChange = (event) => {
        event.preventDefault();

        if (event.target.name === name) {
            dateRangeType = event.target.value;
            const newDateRanges = getDateRangeFromDescription(dateRangeType);
            startDate = newDateRanges.startDate;
            endDate = newDateRanges.endDate;
        } else if (event.target.name.endsWith("startDate")) {
            dateRangeType = Custom;
            startDate = event.target.value || "";
        }
        else if (event.target.name.endsWith("endDate")) {
            dateRangeType = Custom;
            endDate = event.target.value || "";
        }

        onSelectDate(dateRangeType, startDate, endDate);
    };

    const dateColumnClass = customOnly ? "" : "date-select-custom";

    return (
        <>
            {
                !customOnly &&
                <GridColumn columnClass={`date-column`}>
                    <SelectField
                        defaultOption={defaultOption}
                        disabled={disabled}
                        includeDefaultOption={includeDefaultOption}
                        medium={medium}
                        name={name}
                        onChange={onChange}
                        options={options}
                        label={label}
                        labelClass={labelClass}
                        showLabel={showLabel}
                        value={dateRangeType}
                        wrapperClass={wrapperClass}
                    />
                </GridColumn>
            }
            {
                (dateRangeType === Custom || customOnly) &&
                <>
                    <GridColumn columnClass={dateColumnClass}>
                        <DateTimePickerField
                            disabled={disabled}
                            label={`Start Date`}
                            medium={medium}
                            name={`${name}_startDate`}
                            onChange={onChange}
                            showLabel={showLabel}
                            value={startDate}
                        />
                    </GridColumn>
                    <GridColumn columnClass={dateColumnClass}>
                        <DateTimePickerField
                            disabled={disabled}
                            label={`End Date`}
                            medium={medium}
                            name={`${name}_endDate`}
                            onChange={onChange}
                            showLabel={showLabel}
                            value={endDate}
                        />
                    </GridColumn>
                </>
            }
        </>
    );
};

DateSelectField.propTypes = {
    customOnly: PropTypes.bool,
    dateRangeType: PropTypes.string.isRequired,
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
    endDate: PropTypes.string.isRequired,
    includeDefaultOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    medium: PropTypes.number,
    name: PropTypes.string.isRequired,
    onSelectDate: PropTypes.func.isRequired,
    optionsToUse: PropTypes.string,
    showLabel: PropTypes.bool,
    startDate: PropTypes.string.isRequired,
    wrapperClass: PropTypes.string
};

export default DateSelectField;