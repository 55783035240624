import PropTypes from "prop-types";
import React from "react";
import GridTable from "../../../../components/common/GridTable";

export const BpieDistrictPieDetailsPriorityIndicators = ({
                                                             dbpiePriorityIndicators,
                                                             sbpiePriorityIndicators
                                                         }) => {
    return <>
        <GridTable>
            <thead>
                <tr>
                    <th>District BPIE Priority Indicators</th>
                </tr>
            </thead>
            <tbody>
            {
                dbpiePriorityIndicators.map((indicator, key) => {
                    return <tr key={`dbpie_${key}`}>
                        <td>
                            <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                        </td>
                    </tr>;
                })
            }
            {
                dbpiePriorityIndicators.length === 0 &&
                <tr>
                    <td>
                        <strong>No District BPIE Priority Indicators have been set.</strong>
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>

        <GridTable>
            <thead>
            <tr>
                <th>Top School BPIE Priority Indicators</th>
            </tr>
            </thead>
            <tbody>
            {
                sbpiePriorityIndicators.map((indicator, key) => {
                    return <tr key={`sbpie_${key}`}>
                        <td>
                            <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary} <strong>{indicator.percentageDescription}</strong>
                        </td>
                    </tr>;
                })
            }
            {
                sbpiePriorityIndicators.length === 0 &&
                <tr>
                    <td>
                        <strong>No School BPIE Priority Indicators have been set.</strong>
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>
;
};

BpieDistrictPieDetailsPriorityIndicators.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    sbpiePriorityIndicators: PropTypes.array.isRequired
};