import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../../components/common/commonUtilities";
import FormTable from "../../../../../components/common/FormTable";
import DateTimePickerField from "../../../../../components/common/inputs/DateTimePickerField";
import {
    clearInputFormErrorFields, convertFormInputObjectToObject,
    inputFormIsValid, useFormInputObject
} from "../../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../../components/common/inputs/SelectField";
import TextArea from "../../../../../components/common/inputs/TextArea";
import {NotifyUser} from "../../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";

export const BpieDistrictPieProgressMonitoringDetailsForm = ({
                                                                 districtContacts,
                                                                 dpieProgressMonitoring,
                                                                 handleCancelDpieProgressMonitoringDetails,
                                                                 handleDataChanged,
                                                                 handleSaveDistrictPieProgressMonitoringDetails
                                                             }) => {
    const dpieProgressMonitoringDetailsForm = useFormInputObject({...dpieProgressMonitoring}, () => handleDataChanged());
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    const handleCancelDpieProgressMonitoringDetailsClick = (event) => {
        event.preventDefault();
        handleCancelDpieProgressMonitoringDetails();
    };

    const handleSaveDpieProgressMonitoringDetailsClick = (event) => {
        event.preventDefault();
        saveDistrictProgressMonitoringDetails();
    };

    const saveDistrictProgressMonitoringDetails = () => {
        const isValid = validateForm();
        if (isValid) {
            const result = convertFormInputObjectToObject(dpieProgressMonitoringDetailsForm);
            handleSaveDistrictPieProgressMonitoringDetails(result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(dpieProgressMonitoringDetailsForm);

        if (isTrimmedStringEmpty(dpieProgressMonitoringDetailsForm.districtContactId.value))
            dpieProgressMonitoringDetailsForm.districtContactId.setError("Required");

        if (isTrimmedStringEmpty(dpieProgressMonitoringDetailsForm.teamMembers.value))
            dpieProgressMonitoringDetailsForm.teamMembers.setError("Required");

        if (isTrimmedStringEmpty(dpieProgressMonitoringDetailsForm.dateMeetingHeld.value))
            dpieProgressMonitoringDetailsForm.dateMeetingHeld.setError("Required");

        let isValid = inputFormIsValid(dpieProgressMonitoringDetailsForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    return <>
        <FormTable>
            <SelectField
                {...dpieProgressMonitoringDetailsForm.districtContactId}
                defaultOption={`Select District Contact`}
                disabled={!isEditable}
                includeDefaultOption
                label={`District Contact`}
                medium={6}
                options={districtContacts}
                showLabel
            />
            <TextArea
                {...dpieProgressMonitoringDetailsForm.teamMembers}
                disabled={!isEditable}
                label={'Add all team member names and titles/positions.'}
                showLabel
            />
            <DateTimePickerField
                {...dpieProgressMonitoringDetailsForm.dateMeetingHeld}
                disabled={!isEditable}
                inputClass={`input--small`}
                label="Date Meeting Held"
                showLabel
            />
        </FormTable>

        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
            <Button
                buttonType={ButtonTypes.SAVE}
                label={"Save Progress Monitoring Details"}
                name="btnSave"
                onClick={handleSaveDpieProgressMonitoringDetailsClick}
            />
            <Button
                buttonType={ButtonTypes.CANCEL}
                label={`Cancel`}
                name={`btnCancelDpieProgressMonitoringDetails`}
                onClick={handleCancelDpieProgressMonitoringDetailsClick}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieProgressMonitoringDetailsForm.propTypes = {
    districtContacts: PropTypes.array,
    dpieProgressMonitoring: PropTypes.object.isRequired,
    handleCancelDpieProgressMonitoringDetails: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveDistrictPieProgressMonitoringDetails: PropTypes.func.isRequired
};