import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../../components/common/commonUtilities";
import FormTable from "../../../../../components/common/FormTable";
import {TextField} from "../../../../../components/common/inputs";
import {
    clearInputFormErrorFields, convertFormInputObjectToObject,
    inputFormIsValid, useFormInputObject
} from "../../../../../components/common/inputs/inputUtility";
import {MonthYearField} from "../../../../../components/common/inputs/MonthYearField";
import TextAreaEditor from "../../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../../../../../components/common/NotifyUser";
import SectionHeader from "../../../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";

export const BpieDistrictPieGoalActionForm = ({
                                                  actionStep,
                                                  handleDataChanged,
                                                  handleSaveActionStep,
                                                  isAdd,
                                                  setEditingActionStepId
                                              }) => {
    const actionStepForm = useFormInputObject({...actionStep}, () => handleDataChanged());

    const handleCancelActionStepClick = (event) => {
        event.preventDefault();
        setEditingActionStepId(null);
        handleDataChanged(false);
    };

    const handleSaveActionStepClick = (event) => {
        event.preventDefault();
        saveActionStep();
    };

    const saveActionStep = () => {
        const isValid = validateForm();
        if (isValid) {
            const result = convertFormInputObjectToObject(actionStepForm);
            handleSaveActionStep(actionStep.actionStepId, result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(actionStepForm);

        if (isTrimmedStringEmpty(actionStepForm.actionStepDescription.value))
            actionStepForm.actionStepDescription.setError("Required");

        if (isTrimmedStringEmpty(actionStepForm.responsibleForCompletion.value))
            actionStepForm.responsibleForCompletion.setError("Required");

        if (isTrimmedStringEmpty(actionStepForm.completeByDate.value)
            || !actionStepForm.completeByDate.isValid)
            actionStepForm.completeByDate.setError("Required");

        if (isTrimmedStringEmpty(actionStepForm.followUp.value))
            actionStepForm.followUp.setError("Required");

        if (isTrimmedStringEmpty(actionStepForm.evidence.value))
            actionStepForm.evidence.setError("Required");

        let isValid = inputFormIsValid(actionStepForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    return <>
        <SectionHeader>
            <h3>{isAdd ? `Add New Action Step` : `Action Step ${actionStepForm.actionStepNumber.value}`}</h3>
        </SectionHeader>
        <FormTable>
            <TextAreaEditor
                {...actionStepForm.actionStepDescription}
                label={'Action Step Description'}
                showLabel={false}
            />

            <TextField
                {...actionStepForm.responsibleForCompletion}
                label={'By Whom'}
                showLabel
                helpText={`FIN, District, Other Collaborative Partner`}
            />

            <MonthYearField
                {...actionStepForm.completeByDate}
                legend="By When"
                showLegend
                helpText={`Deadline for Completion`}
            />

            <TextField
                {...actionStepForm.followUp}
                label={'Follow-Up'}
                showLabel
                helpText={`Measurable and aligned with each action step`}
            />

            <TextField
                {...actionStepForm.evidence}
                label={'By What'}
                showLabel
                helpText={`Provide evidence that will demonstrate completion of Action Step`}
            />
        </FormTable>

        <ButtonBar>
            <Button
                buttonType={ButtonTypes.SAVE}
                label={"Save Action Step"}
                name="btnSave"
                onClick={handleSaveActionStepClick}
            />
            <Button
                buttonType={ButtonTypes.CANCEL}
                label={`Cancel`}
                name={`btnCancelActionStep`}
                onClick={handleCancelActionStepClick}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieGoalActionForm.propTypes = {
    actionStep: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveActionStep: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    setEditingActionStepId: PropTypes.func.isRequired
};