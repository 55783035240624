import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import {BpieDistrictPieSummaryView} from "./BpieDistrictPieSummaryView";
import {DBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";

export const BpieDistrictPieSummaryContainer = ({
                                                    districtBpie,
                                                    handleDistrictBpieSearchRedirect,
                                                    handleDistrictPieSectionsRedirect,
                                                    updatePageTitleDetails
                                                }) => {
    useEffect(() => {
        if(isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        updatePageTitleDetails();
    }, [districtBpie]);

    return (
        <BpieDistrictPieSummaryView
            districtPies={districtBpie.districtPies}
            handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
        />
    );
}

BpieDistrictPieSummaryContainer.propTypes = {
    districtBpie: PropTypes.object.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};