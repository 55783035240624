import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import PrintTable from "../../../../components/common/PrintTable";
import React, {Fragment} from "react";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {quartersDateSelectFieldOptions} from "../../../../constants/inputConstants";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";

export const ImplementationsPrintView = ({
                                      activityNumberOutput,
                                      dateRangeType,
                                      handleFileDownload,
                                      implementationsByQuarter,
                                      quarter,
                                      sourceDocumentationFiles}) => {
    const handleClickDownloadSourceDocument = (event, fileId) => {
        event.preventDefault();
        handleFileDownload(fileId);
    };

    if (dateRangeType !== quarter.text && dateRangeType !== quartersDateSelectFieldOptions.yearToDate.description)
        return null;

    const sourceDocumentationForQuarter = sourceDocumentationFiles.filter(f => f.discretionaryProjectQuarter === quarter.id);
    const hasDocuments = sourceDocumentationForQuarter.length > 0;
    const hasImplementations = implementationsByQuarter[quarter.id].length > 0;
    const hasData = hasDocuments || hasImplementations;

    if (!hasData)
        return <p className={`implementations-container`}><em>No source documentation or implementations.</em></p>;

    return (
        <div className={`implementations-container`}>
            <p className={`sourceDocumentation-container`}>
                <strong>Source Documentation: </strong>
                {
                    hasDocuments &&
                    sourceDocumentationForQuarter.map((doc, index) => {
                        const delimiter = index < sourceDocumentationForQuarter.length - 1 ? ", " : "";
                        return <Fragment key={`${activityNumberOutput}_doc_${doc.sourceDocumentationFileId}`}>
                            <Button
                                buttonType={ButtonTypes.LINK}
                                inputDescription={`Source Documentation for Activity ${activityNumberOutput} - ${quarter.text}`}
                                label={doc.name}
                                name={`btnSourceDoc_${activityNumberOutput}_${quarter.id}_${doc.sourceDocumentationFileId}`}
                                onClick={(event) => handleClickDownloadSourceDocument(event, doc.sourceDocumentationFileId)}
                                showLabel
                            />{delimiter}
                        </Fragment>;
                    })
                }
                {
                    !hasDocuments &&
                   <em>None</em>
                }
            </p>

            {
                !hasImplementations &&
                <p><strong>Implementations:</strong> <em>None</em></p>
            }
            {
                hasImplementations &&
                <>
                    <p><strong>Implementations:</strong></p>
                    <PrintTable className={"add-padding"}>
                        <thead className={`no-head-group`}>
                        <tr className="text-center">
                            <th>
                                Date
                            </th>
                            <th>
                                Status Update
                            </th>
                            <th>
                                # of Units
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            implementationsByQuarter[quarter.id].map((implementation, key) => {
                                return <tr key={`${activityNumberOutput}_${quarter.id}_implementation-${key}`} valign={`top`}>
                                    <td className={`text-center`}>
                                        {implementation.implementationDateOutput}
                                    </td>
                                    <td>
                                        <em>{implementation.implementationName}</em>
                                        {
                                            !isTrimmedStringEmpty(implementation.implementationDetails) &&
                                            <>
                                                {` - ${implementation.implementationDetails}`}
                                            </>

                                        }
                                    </td>
                                    <td className={`text-center`}>
                                        {implementation.implementedUnits}
                                    </td>
                                </tr>;
                            })
                        }
                        <tr>
                            <td colSpan={2} className={`text-right`}>
                                <strong>Total Units:</strong>
                            </td>
                            <td className={`text-center`}>{implementationsByQuarter[quarter.id].reduce((a, b) => a + b.implementedUnits, 0)}</td>
                        </tr>
                        </tbody>
                    </PrintTable>
                </>
            }
        </div>
    );
};

ImplementationsPrintView.propTypes = {
    activityNumberOutput: PropTypes.string,
    dateRangeType: PropTypes.string,
    handleFileDownload: PropTypes.func.isRequired,
    implementationsByQuarter: PropTypes.array.isRequired,
    quarter: PropTypes.object.isRequired,
    sourceDocumentationFiles: PropTypes.array,
};
