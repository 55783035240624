import AccountUtility from "../../../api/AccountUtility";
import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import {bpie as policyEvents} from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class BpiePolicy extends DefaultPolicy {
    constructor() {
        super([contexts.BPIE]);
        this.updatePolicy();
    }

    updatePolicy(){
        this._policy[policyEvents.admin.MANAGE] =
            function (allowedContexts, userBehaviors, isFinFacilitator) {
                return !isFinFacilitator && !AccountUtility.isDistrictUser() && verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        this._policy[policyEvents.districtBpieAssessments.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.districtBpieAssessments.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.districtPieAssessments.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.IMPERSONATE] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.REVIEW] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.VIEW], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.CREATE_ACTIVATE] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.schoolBpieAssessments.UPDATE_STATUS] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.teamMember.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.teamMember.REVIEW] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.VIEW], userBehaviors);
            };
    }

}

export {BpiePolicy, policyEvents};