import React from "react";
import {Switch} from "react-router-dom";
import * as contexts from "../../constants/contexts";
import EnsureLoggedIn from "../../containers/account/EnsureLoggedIn";
import withTracker from "../../withTracker";
import {PtsInformationContainer} from "./Information/PtsInformationContainer";
import {PtsDeliverableSummaryContainer} from "./DeliverableSummary/PtsDeliverableSummaryContainer";
import * as ptsLocations from "./ptsLocations";
import PtsAdminWrapper from "./Admin/PtsAdminWrapper";
import {PtsAdminGmsUsersContainer} from "./Admin/GmsUsers/PtsAdminGmsUsersContainer";
import PtsManageProgramsContainer
    from "./Admin/ManagePrograms/PtsManageProgramsContainer";
import PtsManageFiscalAgentsUsersContainer
    from "./Admin/ManagePrograms/ManageFiscalAgentsUsers/PtsManageFiscalAgentsUsersContainer";
import PtsWrapper from "./PtsWrapper";
import {PtsReportsContainer} from "./Reports/PtsReportsContainer";
import PtsSummaryContainer from "./Summary/PtsSummaryContainer";
import PrintLayout from "../layout/PrintLayout";
import PtsHtmlReport from "./Reports/HtmlReports/PtsHtmlReport";

const PtsRoutes = () => {

    return (
        <Switch>
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_SUMMARY.path}
                component={withTracker(PtsSummaryContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_PROJECT_INFORMATION.path}
                component={withTracker(PtsInformationContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_DELIVERABLES_SUMMARY.path}
                component={withTracker(PtsDeliverableSummaryContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_REPORTS.path}
                component={withTracker(PtsReportsContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsWrapper}
            />
            <PrintLayout
                authorizationRequired
                path={ptsLocations.PTS_HTML_REPORTS.path}
                component={withTracker(PtsHtmlReport)}
                context={[contexts.PTS]}
                wrapperPage={PtsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_ADMIN_GMS_USERS.path}
                component={withTracker(PtsAdminGmsUsersContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS.path}
                component={withTracker(PtsManageFiscalAgentsUsersContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={ptsLocations.PTS_ADMIN_MANAGE_PROGRAMS.path}
                component={withTracker(PtsManageProgramsContainer)}
                context={[contexts.PTS]}
                wrapperPage={PtsAdminWrapper}
            />
        </Switch>
    );
};

export default PtsRoutes;