import React from "react";
import PropTypes from "prop-types";
import 'flatpickr/dist/themes/light.css';
import {gridConstants, monthsList} from "../../../constants/inputConstants";
import {createListItem, isTrimmedStringEmpty} from "../commonUtilities";
import GridColumn from "../GridColumn";
import GridRow from "../GridRow";
import {FieldsetLayout, SelectField} from "./index";
import {createFakeEvent} from "./inputUtility";

export const MonthYearField = ({
                            align = "",
                            disabled = false,
                            error,
                            fieldsetClass = "",
                            helpText,
                            legend,
                            legendClass = "",
                            medium,
                            name,
                            onChange,
                            showLegend,
                            small,
                            value = '',
                            yearEnd = new Date().getFullYear() + 1,
                            yearStart = 2024,
                            wrapperClass = ""
                        }) => {

    const handleChange = (event) => {
        const {month, year} = getDateParts(event);
        const dateString = `${month} ${year}`;
        const isValid = !isTrimmedStringEmpty(month) && !isTrimmedStringEmpty(year);
        onChange(createFakeEvent(dateString, name), null, isValid);
    };

    const getDateParts = (event = null) => {
        const dateParts = value.split(" ");
        const newValue = event?.target.value ?? "";
        const id = event?.target.id ?? "";
        const month = !isTrimmedStringEmpty(newValue) && id.startsWith("month") ? newValue : dateParts[0];
        const year = !isTrimmedStringEmpty(newValue) && id.startsWith("year") ? newValue
            : dateParts.length === 2 ? dateParts[1] : "";

        return {month, year};
    };

    if (!showLegend) legendClass += " is-visuallyhidden";

    wrapperClass += " monthYear "
    wrapperClass += disabled ? " is-disabled" : "";

    if (error && error.length > 0) {
        wrapperClass += "has-error";
    }

    const {month: monthValue, year: yearValue} = getDateParts();
    let yearOptionsList = [];
    for(let yearIndex = yearStart; yearIndex <= yearEnd; yearIndex++)
        yearOptionsList.push(createListItem(yearIndex.toString()));

    return (
        <FieldsetLayout
            align={align}
            disabled={disabled}
            error={error}
            fieldsetClass={fieldsetClass}
            helpText={helpText}
            legend={legend}
            legendClass={legendClass}
            medium={medium}
            showLegend={showLegend}
            small={small}
            wrapperClass={wrapperClass}
        >
            <GridRow>
                <GridColumn medium={gridConstants.column.THREE}>
                    <SelectField
                        defaultOption={`Select a month`}
                        disabled={disabled}
                        includeDefaultOption
                        label={`Month for ${legend}`}
                        name={`month_${name}`}
                        onChange={handleChange}
                        options={monthsList}
                        value={monthValue}
                        wrapperClass={`monthYear--month`}
                    />
                </GridColumn>
                <GridColumn medium={gridConstants.column.THREE}>
                    <SelectField
                        defaultOption={`Select a year`}
                        disabled={disabled}
                        includeDefaultOption
                        label={`Year for ${legend}`}
                        name={`year_${name}`}
                        onChange={handleChange}
                        options={yearOptionsList}
                        value={yearValue}
                    />
                </GridColumn>
            </GridRow>
        </FieldsetLayout>
    );
};

MonthYearField.propTypes = {
    align: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fieldsetClass: PropTypes.string,
    helpText: PropTypes.string,
    legend: PropTypes.string.isRequired,
    legendClass: PropTypes.string,
    medium: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    showLegend: PropTypes.bool,
    small: PropTypes.number,
    value: PropTypes.string,
    yearEnd: PropTypes.number,
    yearStart: PropTypes.number,
    wrapperClass: PropTypes.string
};