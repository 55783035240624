import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../common/commonUtilities";
import GridRow from '../common/GridRow';
import GridColumn from '../common/GridColumn';
import {refocusOnPageChange} from "./layoutUtility";

const Header = ({pageTitle, subTitle}) => {

    const displaySplitHeader =() =>
    {
        const splitContent = pageTitle.headerCenter + pageTitle.headerRight;

        return !isTrimmedStringEmpty(splitContent);
    }

    useEffect(refocusOnPageChange, [pageTitle]);

    if(isTrimmedStringEmpty(pageTitle.headerLeft) && isTrimmedStringEmpty(pageTitle.headerCenter) && isTrimmedStringEmpty(pageTitle.headerRight) && isTrimmedStringEmpty(subTitle))
        return null;

    return (
        <header id="page-title">
            <br/>
            {
                !displaySplitHeader() &&
                (pageTitle.headerLeft.length > 0 || subTitle.length > 0) &&
                <>
                    {pageTitle.headerLeft.length > 0 && <h1 tabIndex={"-1"}>{pageTitle.headerLeft}</h1>}
                    {subTitle.length > 0 && <h2>{subTitle}</h2>}
                </>
            }
            {
                displaySplitHeader() &&
                <GridRow rowClass={`split-header`}>
                    <GridColumn medium="4">
                        {
                            !isTrimmedStringEmpty(pageTitle.headerLeft) &&
                            <h1>{pageTitle.headerLeft} </h1>
                        }
                    </GridColumn>
                    <GridColumn medium="4" columnClass="header-center">
                        {
                            !isTrimmedStringEmpty(pageTitle.headerCenter) &&
                            <h1>{pageTitle.headerCenter}</h1>
                        }
                    </GridColumn>
                    <GridColumn medium="4" columnClass="header-right">
                        {
                            !isTrimmedStringEmpty(pageTitle.headerRight) &&
                            <h1>{pageTitle.headerRight}</h1>
                        }
                    </GridColumn>
                </GridRow>
            }
        </header>
    );
};

Header.propTypes = {
    pageTitle: PropTypes.object,
    subTitle: PropTypes.string
};

Header.defaultProps = {
    pageTitle: {
        headerLeft: "",
        headerCenter: "",
        headerRight: ""
    },
    subTitle: ""
};

export default Header;