import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import CollapsibleContainer from "../../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../../components/common/CollapsibleTrigger";
import GridColumn from "../../../../../components/common/GridColumn";
import GridRow from "../../../../../components/common/GridRow";
import HtmlDisplayBlock from "../../../../../components/htmlDisplay/HtmlDisplayBlock";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {gridConstants} from "../../../../../constants/inputConstants";

export const BpieDistrictPieGoalActionInfo = ({
                                                  actionStep,
                                                  handleActionClosed,
                                                  handleActionOpened,
                                                  handleRemoveActionStep,
                                                  isEditable,
                                                  isEditingActionStep,
                                                  isExpanded,
                                                  setEditingActionStepId
                                              }) => {
    const handleEditDpieGoalActionClick = (event) => {
        event.preventDefault();
        setEditingActionStepId(actionStep.actionStepId);
    }

    const handleRemoveDpieGoalActionClick = (event) => {
        event.preventDefault();
        handleRemoveActionStep(actionStep.actionStepId);
    }

    const notificationTrigger = <CollapsibleTrigger
        text={`Action Step ${actionStep.actionStepNumber}`}/>;

    return <>
        <CollapsibleContainer id={`action-container-${actionStep.actionStepId}`}
                              trigger={notificationTrigger} contentOuterClassName={`actionStep--info`}
                              open={isExpanded} onOpen={() => handleActionOpened(actionStep.actionStepId)}
                              onClose={() => handleActionClosed(actionStep.actionStepId)}>
            {
                !isEditingActionStep && isEditable &&
                <ButtonBar position={ButtonBarPositions.TOP}>
                    <Button
                        buttonType={ButtonTypes.EDIT}
                        label={`Edit Action Step ${actionStep.actionStepNumber}`}
                        name={`btnEditActionStep_${actionStep.actionStepNumber}`}
                        onClick={handleEditDpieGoalActionClick}
                    />
                    <Button
                        buttonType={ButtonTypes.DELETE}
                        label={`Delete Action Step ${actionStep.actionStepNumber}`}
                        name={`btnDeleteActionStep_${actionStep.actionStepNumber}`}
                        onClick={handleRemoveDpieGoalActionClick}
                    />
                </ButtonBar>
            }
            <GridRow rowClass={`here`}>
                <GridColumn>
                    <HtmlDisplayBlock html={actionStep.actionStepDescription}/>
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn medium={gridConstants.column.SIX}>
                    <strong>By Whom:</strong><br/>
                    <span className={`help-text`}>(FIN, District, Other Collaborative Partner)</span>
                </GridColumn>
                <GridColumn medium={gridConstants.column.SIX}>
                    {actionStep.responsibleForCompletion}
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn medium={gridConstants.column.SIX}>
                    <strong>By When:</strong><br/>
                    <span className={`help-text`}>(Deadline for Completion)</span>
                </GridColumn>
                <GridColumn medium={gridConstants.column.SIX}>
                    {actionStep.completeByDate}
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn medium={gridConstants.column.SIX}>
                    <strong>Follow-Up:</strong><br/>
                    <span className={`help-text`}>(Measurable and aligned with each action step)</span>
                </GridColumn>
                <GridColumn medium={gridConstants.column.SIX}>
                    {actionStep.followUp}
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn medium={gridConstants.column.SIX}>
                    <strong>By What:</strong><br/>
                    <span
                        className={`help-text`}>(Provide evidence that will demonstrate completion of Action Step)</span>
                </GridColumn>
                <GridColumn medium={gridConstants.column.SIX}>
                    {actionStep.evidence}
                </GridColumn>
            </GridRow>
        </CollapsibleContainer>
    </>;
};

BpieDistrictPieGoalActionInfo.propTypes = {
    actionStep: PropTypes.object.isRequired,
    handleActionClosed: PropTypes.func.isRequired,
    handleActionOpened: PropTypes.func.isRequired,
    handleRemoveActionStep: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditingActionStep: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    setEditingActionStepId: PropTypes.func.isRequired
};