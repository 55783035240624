import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const BpieDistrictPieDetailsAddSbpie = ({
                                                   handleAddTopPriorityIndicators,
                                                   handleDistrictPieSummaryRedirect,
                                                   hasTopPriorityIndicators,
                                                   isEditable
                                               }) => {

    const handleAddTopPriorityIndicatorsClick = (event) => {
        event.preventDefault();
        if (confirm("Are you sure you want to add the Top SBPIE Priority Indicators?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            handleAddTopPriorityIndicators();
    };

    if (hasTopPriorityIndicators) return null;

    return <>
        <p>Aggregated top SBPIE Priority Indicators need to be added before moving on. Any SBPIEs not completed yet will not be included.</p>
        <ButtonBar>
            {
                isEditable &&
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Add Top SBPIE Priority Indicators"}
                    name="btnAddTopPriorityIndicators"
                    onClick={handleAddTopPriorityIndicatorsClick}
                />
            }
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Summary`}
                name={`btnReturnDpieSummary`}
                onClick={handleDistrictPieSummaryRedirect}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieDetailsAddSbpie.propTypes = {
    handleAddTopPriorityIndicators: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    hasTopPriorityIndicators: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
};