import {mockResolveApiCall} from "../../../api/ApiUtility";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../ptsConstants";
import {API_ROOT} from "../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsReportsApi {
    static submitQuarterlyDeliverables(dateWindowId, discretionaryProjectId, quarterSubmitted) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/quarters/${quarterSubmitted}/submitted`,
                RequestTypes.PUT,
                null,
                API_MESSAGES.QUARTERLY_REPORTS.submitted
            )
        );
    }

    static updateReturnOnInvestmentDocumentation(dateWindowId, discretionaryProjectId, fileDetails) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/roi-documentation`,
                RequestTypes.PUT,
                JSON.stringify(fileDetails)
            )
        );
    }

    static getHtmlReportInfo(dateWindowId, discretionaryProjectId, reportType, dateRangeType, startDate, endDate){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/reports/${reportType}/dateRangeType/${dateRangeType}/startDate/${startDate}/endDate/${endDate}`,
                RequestTypes.GET
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsReportsApi {
    static submitQuarterlyDeliverables() {
        return mockResolveApiCall([]);
    }

    static updateReturnOnInvestmentDocumentation() {
        return mockResolveApiCall([]);
    }

    static getHtmlReportInfo(){
        return mockResolveApiCall({});
    }
}

const ptsReportsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsReportsApi : MockPtsReportsApi;
export default ptsReportsApi;