import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import SectionHeader from "../../../../components/common/SectionHeader";
import {createDistrictContactsList} from "../../bpieUtilities";
import bpieDistrictApi from "../bpieDistrictApi";
import {createDbpiePriorityIndicatorsArray} from "../bpieDistrictObjectFactory";
import bpieDistrictPieDetailsApi from "./bpieDistrictPieDetailsApi";
import {BpieDistrictPieDetailsView} from "./BpieDistrictPieDetailsView";
import {DBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";

export const BpieDistrictPieDetailsContainer = ({
                                                    actions,
                                                    dateWindowId,
                                                    districtBpie,
                                                    districtId,
                                                    districtPie,
                                                    districtPieId,
                                                    handleDataChanged,
                                                    handleDistrictBpieSearchRedirect,
                                                    handleDistrictPieSummaryRedirect,
                                                    loadDistrictPie,
                                                    updatePageTitleDetails
                                                }) => {
    const [districtContacts, setDistrictContacts] = useState(null);
    const pageSubTitle = "District PIE Details";

    const handleSaveDistrictPieDetails = (dpieDetails) => {
        actions.executeApi(bpieDistrictPieDetailsApi.saveDistrictPieDetails, [dateWindowId, districtId, districtPieId, dpieDetails])
            .then(() => {
                loadDistrictPie();
            });
    };

    const handleAddTopPriorityIndicators = () => {
        actions.executeApi(bpieDistrictPieDetailsApi.addSbpieTopIndicators, [dateWindowId, districtId, districtPieId])
            .then(() => {
                loadDistrictPie();
            });
    };

    useEffect(() => {
        if (districtContacts)
            return;

        actions.executeApi(bpieDistrictApi.getDistrictContacts, [districtId])
            .then(contacts => setDistrictContacts(createDistrictContactsList(contacts)));
    }, []);

    useEffect(() => {
        if(isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
            return;
        }

        loadDistrictPie();

        updatePageTitleDetails(pageSubTitle);
    }, [districtBpie]);

    if(isNullOrUndefined(districtBpie) || isNullOrUndefined(districtPie)) return null;

    const dbpiePriorityIndicators = createDbpiePriorityIndicatorsArray(districtBpie);

    return <>
        <SectionHeader>
            <h2>{pageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieDetailsView
            districtContacts={districtContacts}
            districtPie={districtPie}
            dbpiePriorityIndicators={dbpiePriorityIndicators}
            handleDataChanged={handleDataChanged}
            handleAddTopPriorityIndicators={handleAddTopPriorityIndicators}
            handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
            handleSaveDistrictPieDetails={handleSaveDistrictPieDetails}
        />
    </>;
}

BpieDistrictPieDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};