import $ from "jquery";
import {useState} from "react";
import {outputStandardDateFormat} from "../../shared/sharedDataUtilities";
import {
	createDescriptionObjectForList,
	createListFromObject,
	isNullOrUndefined,
	isObjectNullOrEmpty,
	isTrimmedStringEmpty,
	objectHasProperty,
	optionIndexInArray
} from "../commonUtilities";
import {
	futureDateSelectFieldOptions,
	otherTextSeparator,
	previousDateSelectFieldOptions
} from "../../../constants/inputConstants";
import {handleScroll} from "../scrollUtilities";
import {createDateSelectFiltersObject} from "./inputObjectFactory";

export function useFormInput(initialValue) {
	const [value, setValue] = useState(initialValue);

	function handleChange(e) {
		const value = e && e.target ? e.target.value : e;
		setValue(value);
	}

	return {
		onChange: handleChange,
		setValue,
		value
	};
}

export function useFormInputObject(initialValue, callbackAfterChange) {
	const [items, setItems] = useState(initialValue);

	function handleChange(e, id, isValid = true) {
		const name = id ? id : e.target.name;
		const item = this ? this : items[name];
		item.setValue(e.target.value, isValid);

		if (callbackAfterChange)
			callbackAfterChange(e, items);
	}

	function setValue(value, isValid = true) {
		const result = {...items};
		if(value !== result[this.name].value) {
			result[this.name].value = value;
			result[this.name].isValid = isValid;
			setItems(result);
			if (callbackAfterChange)
				callbackAfterChange({target:{id:this.name}}, result);
		}
	}

	function setError(error) {
		const result = {...items };
		if(error !== result[this.name].error) {
			result[this.name].error = error;
			setItems(result);
		}
	}

	for (let property in items) {
		if (!objectHasProperty(items, property)) break;
		const item = items[property];
		if (isNullOrUndefined(item) || typeof item !== "object" || Array.isArray(item))
			items[property] = {
				value: item,
				onChange: handleChange,
				setValue,
				setError,
				name: property,
				error: "",
				isValid: true
		};
	}

	return {
		...items
	};
}

export const clearInputFormErrorFields = (object) => {
	for (let property in object) {
		if (objectHasProperty(object, property) && object[property] && object[property].setError) {
			object[property].setError("");
		}
	}
};

export const reinitializeInputFormFields = (object, initialValues) => {
	for (let property in object) {
		if (objectHasProperty(object, property)) {
			if(!object[property].setValue) continue;
			const newValue = objectHasProperty(initialValues, property) ? initialValues[property] : "";
			if (!isObjectNullOrEmpty(object[property])){
				object[property].setValue(newValue);
				object[property].setError("");
			}
		}
	}
};

export const inputFormIsValid = (object, scrollConfig = null) => {
	let isValid = true;

	for (let property in object) {
		if (objectHasProperty(object, property)) {
			if( !isTrimmedStringEmpty(object[property].error) && isValid) {
				isValid = false;
				focusOnFirstFormError(property, scrollConfig);
			}
		}
	}

	return isValid;
};

function focusOnFirstFormError(elementId, scrollConfig) {
	if(isTrimmedStringEmpty(elementId))
		return;

	let element = document.getElementById(elementId);

	if(!element) {
		element = document.getElementById(`${elementId}_0`);
	}

	if(!element) {
		element = document.querySelector(`[id^="${elementId}"]`);
	}

	if(!element)
		return;

	element.focus();
	handleScroll(elementId, scrollConfig);
}

export function convertFormInputObjectToObject(object) {
	let items = clone(object);
	if (!items) return null;
	for (let property in items) {
		if (objectHasProperty(items, property)) {
			const item = items[property];
			if (item && typeof item === "object" && objectHasProperty(item, "onChange")) {
				items[property] = item.value;
			}
		}
	}
	return items;
}

export function createFakeEvent(value, id, object = {}, targetObject = {}, tagName = 'button') {
	const target = Object.assign({}, {value, id, name: id, tagName}, targetObject);

	return Object.assign(object, {target}, {
		preventDefault: () => {
		}
	});
}

function clone(obj) {
	if (null == obj || "object" != typeof obj) return null;
	const copy = obj.constructor();
	for (const attr in obj) {
		if (objectHasProperty(obj, attr))
			copy[attr] = obj[attr];
	}
	return copy;
}

export const CONDITIONALS = {
	and: 1,
	or: 2
};

export function createYesNoObject(isBoolean = true) {
	return {
		Yes: isBoolean ? "True" : "Yes",
		No: isBoolean ? "False" : "No"
	};
}

export function createYesNoNotApplicableObject()
{
	return {
		Yes: createDescriptionObjectForList("Yes", "Yes"),
		No: createDescriptionObjectForList("No", "No"),
		NotApplicable: createDescriptionObjectForList("NotApplicable", "N/A")
	};
}

export function createYesNoNotApplicableList() {
	const options = createYesNoNotApplicableObject();
	return createListFromObject(options);
}

export function getYesNoNotApplicableOption(value) {
	if(isNullOrUndefined(value) || value === "") return "";

	const yesNoNotApplicableObject = createYesNoNotApplicableObject();
	return value.toString().toLowerCase() === yesNoNotApplicableObject.Yes.id.toLowerCase()
		? yesNoNotApplicableObject.Yes.id
		: value.toString().toLowerCase() === yesNoNotApplicableObject.No.id.toLowerCase()
			? yesNoNotApplicableObject.No.id
			: yesNoNotApplicableObject.NotApplicable.id;
}

export function createYesNoList(isBoolean = true) {
	const options = createYesNoObject(isBoolean);
	return createListFromObject(options);
}

export function getYesNoOption(value, isBoolean = true) {
	if(isNullOrUndefined(value) || value === "") return "";

	const yesNoObject = createYesNoObject(isBoolean);
	return value.toString().toLowerCase() === yesNoObject.Yes.toLowerCase()
		? yesNoObject.Yes
		: yesNoObject.No;
}

export function isValueInArray(array, value, wholeWord = true) {
	return optionIndexInArray(array, value, wholeWord) > -1;
}

export function removeValueInArray(array, value, wholeWord = true) {
	const index = optionIndexInArray(array, value, wholeWord);
	array.splice(index, 1);
}

export function updateCheckboxListArray(optionChanged, currentOptions, allOptions) {
	const isChecked = optionIndexInArray(currentOptions, optionChanged, false) > -1;
	let optionsSelected = [];

	if (isChecked) {
		optionsSelected = currentOptions.reduce((agg, option) => {
			if (!option.startsWith(optionChanged))
				agg.push(option);

			return agg;
		}, []);
	}
	else {
		for (let i = 0; i < allOptions.length; i++) {
			if (allOptions[i] === optionChanged)
				optionsSelected.push(optionChanged);
			else {
				let index = optionIndexInArray(currentOptions, allOptions[i], false);
				if (index > -1)
					optionsSelected.push(currentOptions[index]);
			}
		}
	}

	return optionsSelected;
}

export function updateCheckboxOtherValue(arrayOfSelected, selectedValue, otherValue) {
	const index = optionIndexInArray(arrayOfSelected, selectedValue, false);
	arrayOfSelected[index] = generateOtherValue(selectedValue, otherValue);

	if (isValueInArray(arrayOfSelected, selectedValue))
		removeValueInArray(arrayOfSelected, selectedValue);
}

export function generateOtherValue(
	selectedValue,
	otherValue,
	separator = otherTextSeparator,
) {
	const convertedSelectedValue = selectedValue.value || selectedValue;
	return `${convertedSelectedValue}${separator}${otherValue}`;
}

export function appendedOtherValue(currentValue, inputText, separator = otherTextSeparator) {
	const convertedValue = currentValue.value || currentValue;
	const otherText = convertedValue.split(`${inputText}${separator}`);
	return otherText.length === 2 ? otherText[1] : "";
}

export function appendedCheckboxOtherValue(arrayOfSelected, checkboxText, separator = otherTextSeparator) {
	const otherIndex = optionIndexInArray(arrayOfSelected, checkboxText + separator, false);
	if (otherIndex > -1) {
		const otherText = arrayOfSelected[otherIndex].split(separator);
		if (otherText.length === 2)
			return otherText[1].trim();
	}

	return "";
}

export function appendedCheckboxOtherValueExists(checkboxText, arrayOfSelected, separator = otherTextSeparator) {
	const otherIndex = optionIndexInArray(arrayOfSelected, checkboxText, false);
	if (otherIndex > -1) {
		const otherText = arrayOfSelected[otherIndex].split(separator);
		if (otherText.length === 1 || (otherText.length === 2 && otherText[1].trim() === ""))
			return false;
	}

	return true;
}

export function getDateRangeFromDescription(dateRangeType, currentDate = new Date()) {
	let filterUpdate = createDateSelectFiltersObject();
	const day = currentDate.getDay();
	let startDate = null;
	let endDate = null;

	switch(dateRangeType) {
		case previousDateSelectFieldOptions.Custom:
			filterUpdate.startDate = "";
			filterUpdate.endDate = "";
			break;
		case previousDateSelectFieldOptions.ThisWeek:
			startDate = new Date(currentDate);
			startDate.setDate(currentDate.getDate() - day);
			endDate = new Date(startDate);
			endDate.setDate(startDate.getDate() + 6);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case previousDateSelectFieldOptions.LastWeek:
			startDate = new Date(currentDate);
			startDate.setDate(currentDate.getDate() - day - 7);
			endDate = new Date(startDate);
			endDate.setDate(startDate.getDate() + 6);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case previousDateSelectFieldOptions.ThisMonth:
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case previousDateSelectFieldOptions.LastThreeMonths:
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case futureDateSelectFieldOptions.NextWeek:
			startDate = new Date(currentDate);
			startDate.setDate(currentDate.getDate() + 7 - day);
			endDate = new Date(startDate);
			endDate.setDate(startDate.getDate() + 6);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case futureDateSelectFieldOptions.NextThreeMonths:
			startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);
			filterUpdate.startDate = outputStandardDateFormat(startDate, false);
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
		case futureDateSelectFieldOptions.Overdue:
			endDate = new Date(currentDate);
			endDate.setDate(endDate.getDate() - 1);
			filterUpdate.startDate = "";
			filterUpdate.endDate = outputStandardDateFormat(endDate, false);
			break;
	}

	return filterUpdate;
}

export function createMaskFormat(character, regexp) {
	return {
		str: character,
		regexp: regexp
	};
}

//https://html.spec.whatwg.org/multipage/input.html#fakepath-srsly
export function extractFilename(path) {
	if (path.substring(0, 12) === "C:\\fakepath\\")
		return path.substring(12); // modern browser
	let x;
	x = path.lastIndexOf('/');
	if (x >= 0) // Unix-based path
		return path.substring(x+1);
	x = path.lastIndexOf('\\');
	if (x >= 0) // Windows-based path
		return path.substring(x+1);
	return path; // just the filename
}

export const clearUpload = (fileInputId) => {
	let control = $(`#${fileInputId}`);
	control.wrap('<form>').closest('form').get(0).reset();
	control.unwrap();
};

export function isValidPhone(phone){
	const re = /^((\([0-9]{3}\)[\s]?[0-9]{3}[\s\-][0-9]{4})|([0-9]{3}[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}))$/;
	return re.test(String(phone).toLowerCase());
}
