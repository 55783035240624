import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import {BpieDistrictPieIntroView} from "./BpieDistrictPieIntroView";
import {DBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";
import bpieDistrictApi from "../bpieDistrictApi";

const BpieDistrictPieIntroContainer = ({
                                           actions,
                                           districtBpie,
                                           handleDistrictBpieSearchRedirect,
                                           handleDistrictPieSummaryRedirect,
                                           loadDistrictBpie
                                       }) => {
    const updateDistrictBpieStatus = () => {
        if (districtBpie.districtBpieStatus !== DBPIE_ASSESSMENT_STATUS.Finalized.id) {
            handleDistrictPieSummaryRedirect();
            return;
        }

        if (confirm("Are you sure you want to begin the District PIEs ?")) {
            actions.executeApi(bpieDistrictApi.updateDistrictBpieStatus, [districtBpie.districtId, districtBpie.districtBpieId, DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id, ''])
                .then(async () => {
                    await loadDistrictBpie();
                    handleDistrictPieSummaryRedirect();
                });
        }
    }

    useEffect(() => {
        if(isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.Finalized.id) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtBpie]);
    return (
        <BpieDistrictPieIntroView
            updateDistrictBpieStatus={updateDistrictBpieStatus}
        />
    );
}

BpieDistrictPieIntroContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    districtBpie: PropTypes.object.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    loadDistrictBpie: PropTypes.func.isRequired
};

export default BpieDistrictPieIntroContainer;