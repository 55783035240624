import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import GridTable from "../../../../../components/common/GridTable";
import {ReadMore} from "../../../../../components/common/ReadMore";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../../constants/config";

export const BpieDistrictPieGoalsSummaryView = ({
                                                    goals = [],
                                                    handleAddEditGoal,
                                                    handleRemoveGoal
                                                }) => {
    const handleClickEditGoal = (event, goalId) => {
        event.preventDefault();
        handleAddEditGoal(goalId);
    };

    const handleClickAddGoal = (event) => {
        event.preventDefault();
        handleAddEditGoal(emptyGuid);
    };

    const handleClickRemoveGoal = (event, goalId) => {
        event.preventDefault();
        handleRemoveGoal(goalId);
    };

    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
    const buttonType = isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
    const labelType = isEditable ? "Edit" : "View";

    return <>
        <GridTable className={`dpieGoalsSummary`}>
            <thead>
            <tr>
                <th>Goal</th>
                <th className={`text-center`}>Total Actions</th>
                <th className={`text-center`}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
                goals.map((goal, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <ReadMore text={`<strong>Goal ${goal.goalNumber}: </strong> ${goal.goalDescription}`}/>
                            </td>
                            <td className={`text-center`}>{goal.actionSteps.length}</td>
                            <td className={`actions text-center`}>
                                <Button
                                    buttonType={buttonType}
                                    label={`${labelType} Goal ${goal.goalNumber}`}
                                    name={`btnEditGoal-${index}`}
                                    onClick={(event) => handleClickEditGoal(event, goal.dpieGoalId)}
                                    showLabel={false}
                                />
                                {
                                    isEditable &&
                                    <Button
                                        buttonType={ButtonTypes.DELETE}
                                        label={`Remove Goal ${goal.goalNumber}`}
                                        name={`btnRemoveGoal-${index}`}
                                        onClick={(event) => handleClickRemoveGoal(event, goal.dpieGoalId)}
                                        showLabel={false}
                                    />
                                }
                            </td>
                        </tr>
                    );
                })
            }
            {
                isEditable &&
                <tr>
                    <td colSpan={3} className={`text-center`}>
                        <Button
                            buttonType={ButtonTypes.ADD}
                            btnClass={`button--large`}
                            label={`Add Goal`}
                            name={`btnAddGoal`}
                            onClick={handleClickAddGoal}
                            showLabel
                        />
                    </td>
                </tr>
            }
            {
                !isEditable && goals.length === 0 &&
                <tr>
                    <td colSpan={3} className={`text-center`}>
                        No goals available.
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>;
};

BpieDistrictPieGoalsSummaryView.propTypes = {
    goals: PropTypes.array.isRequired,
    handleAddEditGoal: PropTypes.func.isRequired,
    handleRemoveGoal: PropTypes.func.isRequired
};