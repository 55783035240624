import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../bpieLocations"
import {BpieApiMessages} from "../../bpieConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictPieGoalsApi {
    static removeGoal(dateWindowId, districtId, districtPieId, dpieGoalId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/goals/${dpieGoalId}`,
                RequestTypes.DELETE,
                null,
                BpieApiMessages.DPIE.GOAL_REMOVED
            )
        );
    }

    static saveDistrictPieGoal(dateWindowId, districtId, districtPieId, dbpieGoalId, dpieGoalsModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/goals/${dbpieGoalId}`,
                RequestTypes.PUT,
                JSON.stringify(dpieGoalsModel),
                BpieApiMessages.DPIE.GOAL_SAVED
            )
        );
    }

    static removeActionStep(dateWindowId, districtId, districtPieId, dpieGoalId, actionStepId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/goals/${dpieGoalId}/actionSteps/${actionStepId}`,
                RequestTypes.DELETE,
                null,
                BpieApiMessages.DPIE.ACTION_STEP_REMOVED
            )
        );
    }

    static saveDistrictPieActionStep(dateWindowId, districtId, districtPieId, dbpieGoalId, actionStepId, actionStepModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/goals/${dbpieGoalId}/actionSteps/${actionStepId}`,
                RequestTypes.PUT,
                JSON.stringify(actionStepModel),
                BpieApiMessages.DPIE.ACTION_STEP_SAVED
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictPieGoalsApi {
    static removeGoal(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.GOAL_REMOVED);
    }

    static saveDistrictPieGoal(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.GOAL_SAVED);
    }

    static removeActionStep(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.ACTION_STEP_REMOVED);
    }

    static saveDistrictPieActionStep(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.ACTION_STEP_SAVED);
    }
}

const bpieDistrictPieGoalsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictPieGoalsApi : MockBpieDistrictPieGoalsApi;
export default bpieDistrictPieGoalsApi;