import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../bpieLocations"
import {BpieApiMessages} from "../../bpieConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictPieDetailsApi {
    static addSbpieTopIndicators(dateWindowId, districtId, districtPieId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/indicators`,
                RequestTypes.POST,
                null,
                BpieApiMessages.DPIE.SBPIE_TOP_INDICATORS_ADDED
            )
        );
    }

    static saveDistrictPieDetails(dateWindowId, districtId, districtPieId, dpieDetailsModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/details`,
                RequestTypes.PUT,
                JSON.stringify(dpieDetailsModel),
                BpieApiMessages.DPIE.UPDATED
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictPieDetailsApi {
    static addSbpieTopIndicators(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.SBPIE_TOP_INDICATORS_ADDED);
    }

    static saveDistrictPieDetails(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.UPDATED);
    }
}

const bpieDistrictPieDetailsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictPieDetailsApi : MockBpieDistrictPieDetailsApi;
export default bpieDistrictPieDetailsApi;