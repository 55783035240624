import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../../constants/config";
import {DistrictPieTask} from "../../../bpieConstants";
import {createActionStep} from "../../bpieDistrictObjectFactory";
import {BpieDistrictPieGoalActionForm} from "./BpieDistrictPieGoalActionForm";
import {BpieDistrictPieGoalActionInfo} from "./BpieDistrictPieGoalActionInfo";
import {BpieDistrictPieGoalDetailsInfo} from "./BpieDistrictPieGoalDetailsInfo";
import {BpieDistrictPieDetailsGoalForm} from "./BpieDistrictPieGoalForm";

export const BpieDistrictPieGoalDetailsView = ({
                                                   actionStepsExpanded,
                                                   dataChanged,
                                                   dbpiePriorityIndicators,
                                                   districtPieId,
                                                   dpieGoal,
                                                   editingActionStepId,
                                                   editingDpieGoalId,
                                                   handleDataChanged,
                                                   handleDistrictPieSectionsRedirect,
                                                   handleRemoveActionStep,
                                                   handleSaveActionStep,
                                                   handleSaveDistrictPieGoalDetails,
                                                   isAdd,
                                                   isEditable,
                                                   isEditingActionStep,
                                                   isEditingGoal,
                                                   sbpiePriorityIndicators,
                                                   setActionStepsExpanded,
                                                   setEditingDpieGoalId,
                                                   setEditingActionStepId
                                               }) => {
    const handleCancelDpieGoalDetailsClick = (event) => {
        event.preventDefault();

        if (editingDpieGoalId === emptyGuid)
            redirectToGoalSummary();

        setEditingDpieGoalId(null);
        handleDataChanged(false);
    };

    const redirectToGoalSummary = () => {
        if (dataChanged) {
            if (!confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
                return;
        }
        handleDataChanged(false);
        handleDistrictPieSectionsRedirect(districtPieId, DistrictPieTask.Goals.id);
    };

    const handleClickAddActionStep = (event) => {
        event.preventDefault();
        setEditingActionStepId(emptyGuid);
    };

    const handleActionOpened = (actionStepId) => {
        let currentOpened = [...actionStepsExpanded];
        if (!currentOpened.some(s => s === actionStepId)) {
            currentOpened.push(actionStepId);
            setActionStepsExpanded(currentOpened);
        }
    };

    const handleActionClosed = (actionStepId) => {
        setActionStepsExpanded(actionStepsExpanded.filter(f => f !== actionStepId));
    };

    const handleExpandCollapseAllClick = (event) => {
        event.preventDefault();
        const isAllExpanded = actionStepsExpanded.length === dpieGoal.actionSteps.length;
        if (isAllExpanded)
            setActionStepsExpanded([]);
        else {
            const actionStepIds = dpieGoal.actionSteps.map(actionStep => actionStep.actionStepId);
            setActionStepsExpanded(actionStepIds);
        }
    };

    const expandCollapseAllLabel = actionStepsExpanded.length === dpieGoal.actionSteps.length
        ? `Collapse All`
        : `Expand All`;

    return <>
        {
            isEditingGoal &&
            <BpieDistrictPieDetailsGoalForm
                dbpiePriorityIndicators={dbpiePriorityIndicators}
                dpieGoal={dpieGoal}
                handleCancelDpieGoalDetailsClick={handleCancelDpieGoalDetailsClick}
                handleDataChanged={handleDataChanged}
                handleSaveDistrictPieGoalDetails={handleSaveDistrictPieGoalDetails}
                isAdd={isAdd}
                sbpiePriorityIndicators={sbpiePriorityIndicators}
            />
        }
        {
            !isEditingGoal &&
            <>
                <BpieDistrictPieGoalDetailsInfo
                    dpieGoal={dpieGoal}
                    isEditable={isEditable}
                    isEditingActionStep={isEditingActionStep}
                    redirectToGoalSummary={redirectToGoalSummary}
                    setEditingDpieGoalId={setEditingDpieGoalId}
                />

                {
                    dpieGoal.actionSteps.length > 0 &&
                    <div className={`actionStep--expandCollapse`}>
                        <Button
                            buttonType={ButtonTypes.LINK}
                            label={`${expandCollapseAllLabel} Action Steps`}
                            name={'btnExpandCollapseAll'}
                            onClick={handleExpandCollapseAllClick}
                            showLabel
                        />
                    </div>
                }
                {
                    dpieGoal.actionSteps.map((actionStep, index) => {
                        const isExpanded = actionStepsExpanded.some(s => s === actionStep.actionStepId);
                        return (actionStep.actionStepId === editingActionStepId)
                            ? <BpieDistrictPieGoalActionForm
                                key={index}
                                actionStep={actionStep}
                                handleDataChanged={handleDataChanged}
                                handleSaveActionStep={handleSaveActionStep}
                                isAdd={false}
                                setEditingActionStepId={setEditingActionStepId}
                            />
                            : <BpieDistrictPieGoalActionInfo
                                key={index}
                                actionStep={actionStep}
                                handleActionClosed={handleActionClosed}
                                handleActionOpened={handleActionOpened}
                                handleRemoveActionStep={handleRemoveActionStep}
                                isEditable={isEditable}
                                isEditingActionStep={isEditingActionStep}
                                isExpanded={isExpanded}
                                setEditingActionStepId={setEditingActionStepId}
                            />;
                    })
                }
                {
                    editingActionStepId === emptyGuid &&
                    <BpieDistrictPieGoalActionForm
                        actionStep={createActionStep()}
                        handleDataChanged={handleDataChanged}
                        handleSaveActionStep={handleSaveActionStep}
                        isAdd={true}
                        setEditingActionStepId={setEditingActionStepId}
                    />
                }
                {
                    isNullOrUndefined(editingActionStepId) && isEditable &&
                    <ButtonBar position={ButtonBarPositions.BOTTOM} className={`actionStep--addActionStep`}>
                        <Button
                            buttonType={ButtonTypes.ADD}
                            btnClass={`button--large`}
                            label={`Add Action Step`}
                            name={`btnAddActionStep`}
                            onClick={handleClickAddActionStep}
                            showLabel
                        />
                    </ButtonBar>
                }
            </>
        }
    </>;
};

BpieDistrictPieGoalDetailsView.propTypes = {
    actionStepsExpanded: PropTypes.array.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtPieId: PropTypes.string.isRequired,
    dpieGoal: PropTypes.object.isRequired,
    editingActionStepId: PropTypes.string,
    editingDpieGoalId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    handleRemoveActionStep: PropTypes.func.isRequired,
    handleSaveActionStep: PropTypes.func.isRequired,
    handleSaveDistrictPieGoalDetails: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditingActionStep: PropTypes.bool.isRequired,
    isEditingGoal: PropTypes.bool.isRequired,
    sbpiePriorityIndicators: PropTypes.array.isRequired,
    setActionStepsExpanded: PropTypes.func.isRequired,
    setEditingDpieGoalId: PropTypes.func.isRequired,
    setEditingActionStepId: PropTypes.func.isRequired
};