import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {getReportComponent} from "../ptsReportUtility";
import ptsReportsApi from "../ptsReportsApi";

const PtsHtmlReport = ({
                           actions,
                           dateRangeType,
                           dateWindowId,
                           discretionaryProjectId,
                           endDate,
                           reportType,
                           startDate,
                       }) => {
    const [htmlReportInfo, setHtmlReportInfo] = useState(null);

    useEffect(() => {
        const initializeData = async () => {
            await actions.executeApi( ptsReportsApi.getHtmlReportInfo, [dateWindowId, discretionaryProjectId, reportType, dateRangeType, startDate, endDate])
                .then(setHtmlReportInfo);
        };

        if (htmlReportInfo == null)
            initializeData().catch(console.error);
    }, []);

    if (!htmlReportInfo || !reportType)
        return null;

    const Component = getReportComponent(reportType);

    return <>
        {
            Component &&
            <Component actions={actions} dateRangeType={dateRangeType} htmlReportInfo={htmlReportInfo} />
        }
        {
            !Component &&
            <p>Report did not load correctly. Please close this window and try again.</p>
        }
    </>;
};

PtsHtmlReport.propTypes = {
    actions: PropTypes.object.isRequired,
    dateRangeType: PropTypes.string,
    dateWindowId: PropTypes.string,
    discretionaryProjectId: PropTypes.string,
    endDate: PropTypes.string,
    reportType: PropTypes.string,
    startDate: PropTypes.string,
};

export default PtsHtmlReport;
