import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../../../components/common/commonUtilities";
import GridTable from "../../../../../components/common/GridTable";
import HtmlDisplayBlock from "../../../../../components/htmlDisplay/HtmlDisplayBlock";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";

export const BpieDistrictPieGoalDetailsInfo = ({
                                                   dpieGoal,
                                                   isEditable,
                                                   isEditingActionStep,
                                                   redirectToGoalSummary,
                                                   setEditingDpieGoalId
                                               }) => {
    const handleEditDpieGoalDetailsClick = (event) => {
        event.preventDefault();
        setEditingDpieGoalId(dpieGoal.dpieGoalId);
    }

    const handleReturnDpieGoalSummaryClick = (event) => {
        event.preventDefault();
        redirectToGoalSummary();
    }

    return <>
        <ButtonBar position={ButtonBarPositions.STICKY}>
            {
                !isEditingActionStep && isEditable &&
                <Button
                    buttonType={ButtonTypes.EDIT}
                    label={`Edit Goal ${dpieGoal.goalNumber}`}
                    name={`btnEditDpieGoalDetails`}
                    onClick={handleEditDpieGoalDetailsClick}
                />
            }

            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Goal Summary`}
                name={`btnReturnDpieGoalSummary`}
                onClick={handleReturnDpieGoalSummaryClick}
            />
        </ButtonBar>

        <GridTable>
            <thead>
            <tr>
                <th>Selected Indicators</th>
            </tr>
            </thead>
            <tbody>
            {
                !isArrayNullOrEmpty(dpieGoal.dbpieIndicators) &&
                <tr>
                    <td>
                        <strong>District BPIE Assessment Priority Indicator(s): </strong>
                        <ul>
                        {
                            dpieGoal.dbpieIndicators.map((indicator, index) => {
                                return <li key={`dbpie_${index}`}>
                                    <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                                </li>;
                            })
                        }
                        </ul>
                    </td>
                </tr>
            }
            {
                !isArrayNullOrEmpty(dpieGoal.sbpieIndicators) &&
                <tr>
                    <td>
                        <strong>School BPIE Assessment Priority Indicator(s): </strong>
                        <ul>
                            {
                                dpieGoal.sbpieIndicators.map((indicator, index) => {
                                    return <li key={`sbpie_${index}`}>
                                        <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                                        <strong>{indicator.percentageDescription}</strong>
                                    </li>;
                                })
                            }
                        </ul>
                    </td>
                </tr>
            }
            {
                !isTrimmedStringEmpty(dpieGoal.additionalBpiePriorityIndicator) &&
                <tr>
                    <td>
                        <strong>Additional BPIE Assessment
                            Indicator: </strong> {dpieGoal.additionalBpiePriorityIndicator}
                    </td>
                </tr>
            }
            {
                !isTrimmedStringEmpty(dpieGoal.sppIndicatorsString) &&
                <tr>
                    <td>
                        <strong>SPP Indicators: </strong> {dpieGoal.sppIndicatorsString}
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>
        <h3>Goal {dpieGoal.goalNumber}</h3>
        <HtmlDisplayBlock html={dpieGoal.goalDescription} />
    </>;
};

BpieDistrictPieGoalDetailsInfo.propTypes = {
    dpieGoal: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditingActionStep: PropTypes.bool.isRequired,
    redirectToGoalSummary: PropTypes.func.isRequired,
    setEditingDpieGoalId: PropTypes.func.isRequired
};