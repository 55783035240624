import React from "react";
import {Switch} from "react-router-dom";
import * as contexts from "../../constants/contexts";
import EnsureLoggedIn from "../../containers/account/EnsureLoggedIn";
import withTracker from "../../withTracker";
import * as bpieLocations from "./bpieLocations";
import BpieSchoolWrapper from "./BpieSchoolWrapper";
import BpieDistrictWrapper from "./District/BpieDistrictWrapper";
import DistrictAssessmentContainer from "./District/DistrictAssessmentEdit/DistrictAssessmentContainer";
import DistrictAssessmentPrioritiesContainer
        from "./District/DistrictAssessmentPriorities/DistrictAssessmentPrioritiesContainer";
import DistrictAssessmentSummaryContainer
        from "./District/DistrictAssessmentSummary/DistrictAssessmentSummaryContainer";
import {BpieDistrictPieDetailsContainer} from "./District/DistrictPieDetails/BpieDistrictPieDetailsContainer";
import {
        BpieDistrictPieGoalDetailsContainer
} from "./District/DistrictPieGoals/DistrictPieGoalDetails/BpieDistrictPieGoalDetailsContainer";
import {
        BpieDistrictPieGoalsSummaryContainer
} from "./District/DistrictPieGoals/DistrictPieGoalsSummary/BpieDistrictPieGoalsSummaryContainer";
import BpieDistrictPieIntroContainer from "./District/DistrictPieIntroduction/BpieDistrictPieIntroContainer";
import BpieDistrictInfoContainer from "./District/DistrictInformation/BpieDistrictInfoContainer";
import {
        BpieDistrictPieProgressMonitoringDetailsContainer
} from "./District/DistrictPieProgressMonitorings/DistrictPieProgressMonitoringDetails/BpieDistrictPieProgressMonitoringDetailsContainer";
import {
        BpieDistrictPieProgressMonitoringsSummaryContainer
} from "./District/DistrictPieProgressMonitorings/DistrictPieProgressMonitoringsSummary/BpieDistrictPieProgressMonitoringsSummaryContainer";
import {BpieDistrictPieSummaryContainer} from "./District/DistrictPieSummary/BpieDistrictPieSummaryContainer";
import SchoolAssessmentSummaryContainer from "./School/SchoolAssessmentSummary/SchoolAssessmentSummaryContainer";
import BpieSchoolInfoContainer from "./School/SchoolInformation/BpieSchoolInfoContainer";
import BpieAssessmentIntroContainer from "./Assessment/AssessmentIntro/BpieAssessmentIntroContainer";
import SchoolAssessmentContainer from "./School/SchoolAssessmentEdit/SchoolAssessmentContainer";
import SchoolAssessmentPrioritiesContainer from "./School/SchoolAssessmentPriorities/SchoolAssessmentPrioritiesContainer";
import BpieDistrictDashboardContainer from "./District/Dashboard/BpieDistrictDashboardContainer";
import SbpieSearchContainer from "./School/SbpieSearch/SbpieSearchContainer";
import BpieSchoolSummaryContainer from "./School/SchoolSummary/BpieSchoolSummaryContainer";
import DbpieSearchContainer from "./District/DbpieSearch/DbpieSearchContainer";
import SchoolImportContainer from "./Admin/SchoolImport/SchoolImportContainer";
import BpieAdminWrapper from "./Admin/BpieAdminWrapper";
import SBpieCreateContainer from "./Admin/SBpieCreate/SBpieCreateContainer";
import RegionContainer from "./Admin/Regions/RegionContainer";
import ManageFinContainer from "./Admin/ManageFinFacilitators/ManageFinContainer";

const BpieRoutes = () => {
    return (<Switch>
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_LANDING.path}
                component={withTracker(BpieSchoolInfoContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_LANDING_WITH_SCHOOL_BPIE.path}
                component={withTracker(BpieSchoolInfoContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_ASSESSMENT_INTRO.path}
                component={withTracker(BpieAssessmentIntroContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_ASSESSMENT.path}
                component={withTracker(SchoolAssessmentContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_ASSESSMENT_PRIORITIES.path}
                component={withTracker(SchoolAssessmentPrioritiesContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.BPIE_ASSESSMENT_SUMMARY.path}
                component={withTracker(SchoolAssessmentSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieSchoolWrapper}
            />

            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_BPIE_SUMMARY.path}
                component={withTracker(BpieDistrictDashboardContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_SBPIE_SEARCH.path}
                component={withTracker(SbpieSearchContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DBPIE_SEARCH.path}
                component={withTracker(DbpieSearchContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_SCHOOL_SUMMARY.path}
                component={withTracker(BpieSchoolSummaryContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DBPIE_INFO.path}
                component={withTracker(BpieDistrictInfoContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_INTRO.path}
                component={withTracker(BpieDistrictPieIntroContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_SUMMARY.path}
                component={withTracker(BpieDistrictPieSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_DETAILS.path}
                component={withTracker(BpieDistrictPieDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_GOAL_DETAILS.path}
                component={withTracker(BpieDistrictPieGoalDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_GOALS.path}
                component={withTracker(BpieDistrictPieGoalsSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING_DETAILS.path}
                component={withTracker(BpieDistrictPieProgressMonitoringDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING.path}
                component={withTracker(BpieDistrictPieProgressMonitoringsSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DBPIE_ASSESSMENT.path}
                component={withTracker(DistrictAssessmentContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DBPIE_SUMMARY.path}
                component={withTracker(DistrictAssessmentSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.DISTRICT_DBPIE_PRIORITIES.path}
                component={withTracker(DistrictAssessmentPrioritiesContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />

            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_SBPIE_SEARCH.path}
                component={withTracker(SbpieSearchContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DBPIE_SEARCH.path}
                component={withTracker(DbpieSearchContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_SCHOOL_SUMMARY.path}
                component={withTracker(BpieSchoolSummaryContainer)}
                context={[contexts.BPIE]}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DBPIE_INFO.path}
                component={withTracker(BpieDistrictInfoContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_INTRO.path}
                component={withTracker(BpieDistrictPieIntroContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_SUMMARY.path}
                component={withTracker(BpieDistrictPieSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_DETAILS.path}
                component={withTracker(BpieDistrictPieDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_GOAL_DETAILS.path}
                component={withTracker(BpieDistrictPieGoalDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_GOALS.path}
                component={withTracker(BpieDistrictPieGoalsSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_PROGRESS_MONITORING_DETAILS.path}
                component={withTracker(BpieDistrictPieProgressMonitoringDetailsContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DPIE_PROGRESS_MONITORING.path}
                component={withTracker(BpieDistrictPieProgressMonitoringsSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DBPIE_ASSESSMENT.path}
                component={withTracker(DistrictAssessmentContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DBPIE_SUMMARY.path}
                component={withTracker(DistrictAssessmentSummaryContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_DBPIE_PRIORITIES.path}
                component={withTracker(DistrictAssessmentPrioritiesContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieDistrictWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_BPIE_IMPORT.path}
                component={withTracker(SchoolImportContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_SBPIE_CREATE.path}
                component={withTracker(SBpieCreateContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_BPIE_REGIONS.path}
                component={withTracker(RegionContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieAdminWrapper}
            />

            <EnsureLoggedIn
                authorizationRequired
                exact
                path={bpieLocations.STATE_MANAGE_FIN.path}
                component={withTracker(ManageFinContainer)}
                context={[contexts.BPIE]}
                wrapperPage={BpieAdminWrapper}
            />
        </Switch>);
};

export default BpieRoutes;