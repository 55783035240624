import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import {BpieDistrictPieDetailsAddSbpie} from "./BpieDistrictPieDetailsAddSbpie";
import {BpieDistrictPieDetailsForm} from "./BpieDistrictPieDetailsForm";

export const BpieDistrictPieDetailsView = ({
                                               districtContacts,
                                               districtPie,
                                               dbpiePriorityIndicators,
                                               handleDataChanged,
                                               handleAddTopPriorityIndicators,
                                               handleDistrictPieSummaryRedirect,
                                               handleSaveDistrictPieDetails
                                           }) => {

    const hasTopPriorityIndicators = districtPie.topSbpieIndicators.length > 0;
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    return <>
        <BpieDistrictPieDetailsAddSbpie
            handleAddTopPriorityIndicators={handleAddTopPriorityIndicators}
            handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
            hasTopPriorityIndicators={hasTopPriorityIndicators}
            isEditable={isEditable}
        />

        <BpieDistrictPieDetailsForm
            dbpiePriorityIndicators={dbpiePriorityIndicators}
            districtContacts={districtContacts}
            districtPie={districtPie}
            handleDataChanged={handleDataChanged}
            handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
            handleSaveDistrictPieDetails={handleSaveDistrictPieDetails}
            hasTopPriorityIndicators={hasTopPriorityIndicators}
            isEditable={isEditable}
        />
    </>;
};

BpieDistrictPieDetailsView.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtContacts: PropTypes.array,
    districtPie: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleAddTopPriorityIndicators: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    handleSaveDistrictPieDetails: PropTypes.func.isRequired
};