import React from 'react';
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import PropTypes from "prop-types";

export const PtsNoInfoView = ({
                                  quarterlyAudits = [],
                                  requiresReturnOnInvestmentReport = false
                              }) => {

    return (isArrayNullOrEmpty(quarterlyAudits) && !requiresReturnOnInvestmentReport)
        ? <p>No information is available at this time.</p>
        : null;
};

PtsNoInfoView.propTypes = {
    quarterlyAudits: PropTypes.arrayOf(PropTypes.object),
    requiresReturnOnInvestmentReport: PropTypes.bool
};