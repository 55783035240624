import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import GridTable from "../../../../components/common/GridTable";
import SectionHeader from "../../../../components/common/SectionHeader";
import HtmlDisplayInline from "../../../../components/htmlDisplay/HtmlDisplayInline";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {DistrictPieTask, DistrictPieTaskList} from "../../bpieConstants";

export const BpieDistrictPieSummaryView = ({
                                               districtPies = [],
                                               handleDistrictPieSectionsRedirect
                                           }) => {
    const handleClickDistrictPieAction = (event, districtPieId, sectionId) => {
        event.preventDefault();
        if(sectionId === DistrictPieTask.DownloadDistrictPie.id)
            handleDownloadDpie(districtPieId);
        else
            handleDistrictPieSectionsRedirect(districtPieId, sectionId);
    };

    const handleDownloadDpie = (districtPieId) => {
        console.log("Downloading..." - districtPieId);
    };

    return <>
        {
            districtPies.map((districtPie, index) => {
                // todo update definition of when a district PIE is editable
                const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
                const buttonType = isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
                const labelType = isEditable ? "Edit" : "View";
                return (
                    <div key={index} className={`districtPie-summary`}>
                        <SectionHeader>
                            <h2>{districtPie.dateCovered}</h2>
                        </SectionHeader>
                        <GridTable>
                            <thead>
                            <tr>
                                <th>Task</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                DistrictPieTaskList.map((task, taskIndex) => {
                                    const taskStatus = districtPie.districtPieTaskStatuses.find(t => t.dpieTask === task.id);
                                    return (
                                        <tr key={`${districtPie.districtPieId}_${taskIndex}`}>
                                            <td><HtmlDisplayInline html={task.text} /></td>
                                            <td>{taskStatus.dpieTaskStatusDescription}</td>
                                            <td className={`actions text-center`}>
                                                <Button
                                                    buttonType={buttonType}
                                                    disabled={taskStatus.isDisabled}
                                                    label={`${labelType} ${task.text} for District PIE for ${districtPie.dateCovered}`}
                                                    name={`btnDistrictPie-${index}-${taskIndex}`}
                                                    onClick={(event) => handleClickDistrictPieAction(event, districtPie.districtPieId, task.id)}
                                                    showLabel={false}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </GridTable>
                    </div>
                );
            })
        }
    </>;
};

BpieDistrictPieSummaryView.propTypes = {
    districtPies: PropTypes.array.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired
};