import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import SectionHeader from "../../../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../../../bpieConstants";
import * as bpieLocations from "../../../bpieLocations";
import bpieDistrictPieProgressMonitoringsApi from "../bpieDistrictPieProgressMonitoringsApi";
import {BpieDistrictPieProgressMonitoringsSummaryView} from "./BpieDistrictPieProgressMonitoringsSummaryView";

export const BpieDistrictPieProgressMonitoringsSummaryContainer = ({
                                                         actions,
                                                         dateWindowId,
                                                         districtBpie,
                                                         districtBpieId,
                                                         districtId,
                                                         districtPie,
                                                         districtPieId,
                                                         handleDistrictBpieSearchRedirect,
                                                         handleDistrictPieSummaryRedirect,
                                                         isDistrictUser,
                                                         loadDistrictPie,
                                                         normalizeUrlAndRedirect,
                                                         updatePageTitleDetails
                                                     }) => {
    const pageSubTitle = "Progress Monitoring Summary";

    const handleAddEditProgressMonitoring = (progressMonitoringId) => {
        const addEditProgressMonitoringUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, progressMonitoringId)
            : bpieLocations.STATE_DPIE_PROGRESS_MONITORING_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, progressMonitoringId);

        normalizeUrlAndRedirect(addEditProgressMonitoringUrl);
    };

    const handleRemoveProgressMonitoring = (progressMonitoringId) => {
        if(!confirm("Are you sure you want to delete this DPIE progress monitoring?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            return;

        actions.executeApi(bpieDistrictPieProgressMonitoringsApi.removeProgressMonitoring, [dateWindowId, districtId, districtPieId, progressMonitoringId])
            .then(() => {
                loadDistrictPie();
            });
    };

    const handleDistrictPieSummaryRedirectClick = (event) => {
        event.preventDefault();
        handleDistrictPieSummaryRedirect();
    };

    useEffect(() => {
        if(isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
        }

        loadDistrictPie();
    }, [districtBpie]);

    useEffect(() => {
        if(isNullOrUndefined(districtPie)) return;

        const progressMonitoringStatus = districtPie.districtPieTaskStatuses.find(taskStatus => taskStatus.dpieTask === DistrictPieTask.ProgressMonitoring.id);
        if (progressMonitoringStatus?.isDisabled ?? true) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtPie]);

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        updatePageTitleDetails(pageSubTitle);
    }, [districtBpie]);

    if(isNullOrUndefined(districtBpie) || isNullOrUndefined(districtPie)) return null;

    return <>
        <SectionHeader>
            <h2>{pageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieProgressMonitoringsSummaryView
            progressMonitorings={districtPie.dpieProgressMonitorings}
            handleAddEditProgressMonitoring={handleAddEditProgressMonitoring}
            handleRemoveProgressMonitoring={handleRemoveProgressMonitoring}
        />

        <ButtonBar>
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Summary`}
                name={`btnReturnDpieSummary`}
                onClick={handleDistrictPieSummaryRedirectClick}
            />
        </ButtonBar>
    </>;
}

BpieDistrictPieProgressMonitoringsSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object,
    districtBpieId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    normalizeUrlAndRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};