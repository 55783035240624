import React, {useEffect, useState} from "react";
import AccountUtility from "../../api/AccountUtility";
import {PtsSearchCriteria} from "../../components/common/searchCriterias/PtsSearchCriteria";
import {createListItem, isArrayNullOrEmpty, isNullOrUndefined} from "../../components/common/commonUtilities";
import * as accountActions from "../../actions/accountActions";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as fileActions from "../../actions/fileActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getParamsMultiple} from "../../components/layout/getParams";
import {
    filterCurrentAnnualDateWindows,
    generateDateWindowSelectListItemsAfterYear
} from "../../components/shared/sharedDataUtilities";
import ptsManageProgramsApi from "./Admin/ManagePrograms/ptsManageProgramsApi";
import {createProgramObject} from "./Admin/ManagePrograms/ptsManageProgramsObjectFactory";
import {ALL_PTS_ROUTES} from "./ptsLocations";
import ptsApi from "./ptsApi";
import {PtsNavigation} from "./PtsNavigation";
import {createDiscretionaryProjectObject} from "./ptsObjectFactory";

const PtsWrapper = ({
                        actions,
                        allFiscalAgents,
                        allFiscalAgentsLoaded,
                        Component,
                        currentLocation,
                        dateWindows,
                        districtsList,
                        params,
                        props,
                        searchCriteria
                    }) => {
    const [dataChanged, setDataChanged] = useState(false);
    const [programs, setPrograms] = useState(null);
    const [project, setProject] = useState(null);

    const userDetails = AccountUtility.getUserDetails();

    const getDiscretionaryProject = async (dateWindowId, discretionaryProjectId) => {
        if(isNullOrUndefined(discretionaryProjectId) || isNullOrUndefined(dateWindowId)) {
            if(!isNullOrUndefined(project))
                setProject(null);
            return;
        }

        const result = await actions.executeApi(ptsApi.getDiscretionaryProject, [dateWindowId, discretionaryProjectId]);
        updateDiscretionaryProject(result);
    };

    const updateDiscretionaryProject = (updatedDiscretionaryProject) => {
        setProject(createDiscretionaryProjectObject(updatedDiscretionaryProject));
        handleDataChanged(false);
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const initializeData = async () => {
        const result = await actions.executeApi(ptsManageProgramsApi.getPrograms)
        const updatedPrograms = result.map(program => createProgramObject(program));
        setPrograms(updatedPrograms);
        actions.loadPtsIndicators();
    };

    useEffect(() => {
        getDiscretionaryProject(params.dateWindowId, params.discretionaryProjectId).catch(console.error);
    }, [params.discretionaryProjectId, params.dateWindowId])

    useEffect(() => {
        initializeData().catch(console.error);
    }, []);

    if (!allFiscalAgentsLoaded
        || !programs
        || (!isNullOrUndefined(params.discretionaryProjectId) && !isNullOrUndefined(params.dateWindowId) && !project))
        return null;

    const pageSubTitle = `for ${project?.projectName} ${project?.datesCovered}`;

    return <>
        <Component
            {...props}
            {...params}
            actions={actions}
            allFiscalAgents={allFiscalAgents}
            allFiscalAgentsLoaded={allFiscalAgentsLoaded}
            dataChanged={dataChanged}
            Component={Component}
            currentLocation={currentLocation}
            currentUserFiscalAgent={userDetails.Institution || ""}
            currentUserFiscalAgentId={userDetails.InstitutionId?.toLowerCase() || ""}
            dateWindows={dateWindows}
            districtsList={districtsList}
            getDiscretionaryProject={getDiscretionaryProject}
            handleDataChanged={handleDataChanged}
            isFiscalAgentUser={AccountUtility.isFiscalAgentUser()}
            isStateUser={!AccountUtility.isDistrictUser()}
            pageSubTitle={pageSubTitle}
            programs={programs}
            project={project}
            searchCriteria={searchCriteria}
            updateDiscretionaryProject={updateDiscretionaryProject}
            userId={userDetails.Id || ""}
        />

        {
            !isNullOrUndefined(project) && isNullOrUndefined(params.reportType) &&
            <PtsNavigation
                currentLocation={currentLocation}
                history={props.history}
                dataChanged={dataChanged}
                dateWindowId={params.dateWindowId}
                discretionaryProjectId={params.discretionaryProjectId}
                handleDataChanged={handleDataChanged}
            />
        }
    </>;
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    let searchCriteria = state.searchCriteria.PtsSearchCriteria || new PtsSearchCriteria();
    const institutions = [...state.sharedData.institutionsWithDiscretionaryAgencies];
    const allFiscalAgents = institutions.map(ins => {
        return {
            districtSiteSectionTeamMemberDtos: ins.districtSiteSectionTeamMemberDtos,
            text: ins.name,
            value: ins.id,
            institutionType: ins.institutionType,
        };
    });

    const annualDateWindows = filterCurrentAnnualDateWindows([...state.sharedData.dateWindows], false);
    const dateWindows = generateDateWindowSelectListItemsAfterYear(annualDateWindows, "2023");

    const districts = [...state.sharedData.institutions];
    const districtsList = districts.map(ins => createListItem(ins.name, ins.name));

    const multipleParams = getParamsMultiple(props.location.pathname, ALL_PTS_ROUTES);
    const params = !multipleParams ? {} : multipleParams;

    const currentLocation = props.location.pathname;
    const allFiscalAgentsLoaded = !isArrayNullOrEmpty(allFiscalAgents);

    return {
        allFiscalAgents,
        allFiscalAgentsLoaded,
        districtsList,
        Component,
        currentLocation,
        dateWindows,
        params,
        props,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        accountActions,
        apiForLocalStateActions,
        fileActions,
        layoutActions,
        searchCriteriaActions,
        sharedDataActions,
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch),
    };
};

PtsWrapper.propTypes = {
    actions: PropTypes.object,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    allFiscalAgentsLoaded: PropTypes.bool,
    districtsList: PropTypes.array.isRequired,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.array,
    history: PropTypes.object.isRequired,
    params: PropTypes.object,
    props: PropTypes.object,
    searchCriteria: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PtsWrapper);